import React from 'react'

import CloseIcon from '../icons/close-icon.png'

import HomeIcon from '../icons/home.png'
import SMBFundingIcon from '../icons/stack-of-money.png'
import HomeownersInsuranceIcon from '../icons/home-insurance.png'
import AutoInsuranceIcon from '../icons/insurance.png'
import GMDAutoGroupIcon from '../icons/car.png'

import TermsOfUseIcon from '../icons/terms-and-conditions.png'
import TCPAIcon from '../icons/phone.png'
import PrivacyIcon from '../icons/privacy-policy.png'
import ContactUsIcon from '../icons/contact.png'

const Menu = ({ setIsMenuOpened }) => {

	const NavLink = ({ icon, label, route, href }) => {
		const handleClick = () => {
			if(!href) {
				window.location = route
			} else {
				window.location.href = href
			}
		};

		return (
			<div className='mt-[20px] flex items-center cursor-pointer hover:text-mintGreenColor' onClick={handleClick}>
				<img className='h-[30px]' src={icon} alt={label.toLowerCase()} />
				<p className='ml-4 font-sans text-[28px] font-medium'>{label}</p>
			</div>
		);
	}

	return (
		<div className='left-0 w-[430px] p-8 pb-[50px] absolute bg-creamColor flex flex-col z-[99]'>
			<img className='w-[50px] cursor-pointer' src={CloseIcon} alt="close icon" onClick={() => setIsMenuOpened(false)}/>
			<NavLink icon={HomeIcon} label="Home" route="/" />
      <NavLink icon={SMBFundingIcon} label="SMB Funding" route="/smbfunding" />
      <NavLink icon={HomeownersInsuranceIcon} label="Homeowners Insurance" route="/home" />
      <NavLink icon={AutoInsuranceIcon} label="Auto Insurance" route="/auto" style={{ height: '40px' }} />
      <NavLink icon={GMDAutoGroupIcon} label="GMD Auto Group" href="https://gmdautogroup.com/" />
      <div className='mt-[50px]'/>
      <NavLink icon={TermsOfUseIcon} label="Terms of Use" route="/terms" />
      <NavLink icon={TCPAIcon} label="TCPA" route="/tcpa" />
      <NavLink icon={PrivacyIcon} label="Privacy" route="/privacy" style={{ height: '35px' }} />
      <NavLink icon={ContactUsIcon} label="Contact Us" route="/contact" />
		</div>
	)
}

export default Menu