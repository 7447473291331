import React from 'react'

const SubHeader = () => {

	const SubHeaderItem = ({ title, route }) => (
		<div
			className='cursor-pointer mx-2 md:mx-4 lg:mx-4 px-[10px] md:px-[50px] lg:px-[50px] mt-auto h-[90%] grid place-items-center rounded-t-2xl text-[14px] md:text-2xl lg:text-2xl text-center border-t-[1px] border-l-[1px] border-r-[1px] text-white hover:bg-white hover:text-black'
			onClick={() => window.location = route}
		>
			{title}
		</div>
	)

	return (
		<div className='flex items-center justify-center h-[57px] w-full bg-[#B7B6B7] '>
			<SubHeaderItem title="Homeowners Insurance" route="/home"/>
			<SubHeaderItem title="Auto Insurance" route="/auto"/>
		</div>
	)
}

export default SubHeader