import React from 'react'

const Footer = () => {
	return (
		<div className='flex flex-col'>
			<span className='h-[20px] w-full bg-[#B7B6B7] mb-[2px]'/>
			<div className='grid place-items-center py-4 bg-creamColor text-darkGreenColor'>
				<p className='text-[15px] md:text-[17px] lg:text-[17px] font-sans font-medium'>© 2023 Anderson:Kent Financial Services All Right Reserved</p>
				<p className='flex text-[15px] md:text-[17px] lg:text-[17px] font-sans font-medium'>
					<a className='px-2 underline' href="/terms">Terms & Conditions</a>
					<p>|</p>
					<a className='px-2 underline' href="/privacy">Privacy</a>
					<p>|</p>
					<a className='px-2 underline' href="/tcpa">TCPA</a>
				</p>
			</div>
		</div>
	)
}

export default Footer