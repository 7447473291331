import FinanceForm from "./components/FinanceForm";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeroContentSection from "./components/HeroContentSection";
import HeroImageSection from "./components/HeroImageSection";
import IndustriesSection from "./components/IndustriesSection";
import SubHeader from "./components/SubHeader";

const Spacer = () => (
  <div className="mt-[4px] mb-[4px]"/>
)

const App = () => {
  return (
    <div className="app">
      <Header />
      <Spacer />
      <SubHeader />
      <Spacer />
      <HeroImageSection />
      <HeroContentSection />
      <FinanceForm />
      <IndustriesSection />
      <Footer />
    </div>
  );
}

export default App;
