
import React from 'react'

const HeroContentSection = () => {
	return (
		<div className='w-full flex flex-col pt-[35px] pl-[50px] pr-[50px]'>
			<p className='font-sans text-lightBrownColor'>Are you ready to supercharge your business growth? AKFSI's exclusive financing solutions are tailor-made for entrepreneurs and business owners like you. Whether you're expanding, seizing new opportunities, or simply need a financial boost, we've got you covered. With loan amounts ranging from $5,000 to $750,000, no collateral required, and flexible terms up to 36 months, achieving your business goals has never been easier. Say goodbye to endless paperwork and lengthy waits – we're here to fast-track your success.</p>
		</div>
	)
}

export default HeroContentSection