import axios from 'axios'
import React, { useState } from 'react'

const FinanceForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobilePhone: "",
    email: "",
  })

  const [validationErrors, setValidationErrors] = useState({})

  const [loading, setLoading] = useState(false)

	const handleChange = (e) => {
		const { name, value } = e.target
		if(name === 'mobilePhone') {
			// Allow only numbers or an empty string to be set in the state
			const isIntegerOrEmpty = /^-?\d*$/.test(value)
			if(isIntegerOrEmpty) {
				setFormData(prevState => ({
					...prevState,
					[name]: value
				}))
			}
		} else {
			setFormData(prevState => ({
				...prevState,
				[name]: value
			}))
		}
	}

  const validateFields = () => {
    const errors = {}
    if (!formData.firstName.trim()) {
      errors.firstName = 'First name is required.'
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Last name is required.'
    }

    const phoneRegex = /^[0-9]{10}$/
    if(!formData.mobilePhone) {
			errors.mobilePhone = 'Mobile phone is required.'
		} else {
			if (!phoneRegex.test(formData.mobilePhone)) {
				errors.mobilePhone = 'Invalid mobile phone. Please enter a 10-digit number.'
			}
		}

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if(!formData.email) {
			errors.email = 'Email is required.'
		} else {
			if (!emailRegex.test(formData.email)) {
				errors.email = 'Invalid email format.'
			}
		}

    setValidationErrors(errors)

    return Object.keys(errors).length === 0 // Returns true if no errors
  }

  const handleSubmitFormAndRedirect = async () => {
    setValidationErrors({})

    const isValid = validateFields()

		console.log(validationErrors)

    if (!isValid) {
      return
    }

    if (formData.firstName && formData.lastName && formData.mobilePhone && formData.email) {
      setLoading(true)

			// Define the API endpoint using environment variables
			const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_FORM_GUID}`

			// Prepare the data in the format expected by HubSpot
			const hubspotFormData = {
					fields: [
							{
									name: "firstname",
									value: formData.firstName
							},
							{
									name: "lastname",
									value: formData.lastName
							},
							{
									name: "mobilephone",
									value: formData.mobilePhone
							},
							{
									name: "email",
									value: formData.email
							}
					],
			}

			try {
				console.log(formData)
				await axios.post(HUBSPOT_ENDPOINT, hubspotFormData)
				.then(async() => {
					setLoading(false)

					const encodedFormData = encodeURIComponent(JSON.stringify(formData))
					window.location = `/smbfunding?formData=${encodedFormData}`
				})
			} catch (error) {
					console.error('Network error:', error)
					setLoading(false)
			}
		}
  }


	return (
		<div className='mt-[5px] w-full flex flex-col items-center pt-[35px] pl-[50px] pr-[50px] mb-[60px]'>
			<p className='text-mintGreenColor text-[30px]'>Unlock Your Business's Potential – Apply for Fast Financing Now!</p>

			<div className='mt-[30px] flex flex-col w-full'>
				<p className='text-[20px] text-mintGreenColor font-sans mr-auto'><b className='font-sans'>3 Easy Steps</b> to Empower Your Business:</p>

				<div className="mt-4 mx-auto flex flex-col md:flex-row lg:flex-row">
					<div className="w-full md:lg:w-1/2 lg:w-1/2 mr-4 border-[1px] border-b-4 border-lightGreenColor">
						<div className="mb-4">
							<h2 className="mb-2 text-darkGreenColor flex items-center bg-lightGreenColor p-2">
								<p className='font-bold mr-2 font-sans text-[27px] leading-5 h-10 w-10 grid place-items-center rounded-full border-[1px] border-darkGreenColor'>1</p>
								<p className='text-[28px]'>Apply in Minutes</p>
							</h2>
							<p className='text-md text-base font-sans pl-4 pr-4'>Complete our quick 10-minute application <b className="font-sans">without affecting your credit score</b>. Share your business story and financing needs to unlock personalized loan options, terms, payments, and even a line of credit for growth.</p>
						</div>
						<div className="mb-4">
							<h2 className="mb-2 text-darkGreenColor flex items-center bg-lightGreenColor p-2">
								<p className='font-bold mr-2 font-sans text-[27px] leading-5 h-10 w-10 grid place-items-center rounded-full border-[1px] border-darkGreenColor'>2</p>
								<p className='text-[28px]'>Your Success, Our Commitment</p>
							</h2>
							<p className='text-md text-base font-sans pl-4 pr-4'>Once you submit your application, our local loan consultant will swiftly connect with you. They'll work closely to understand your unique requirements and guide you through the approval process, ensuring you get the financing that suits your goals.</p>
						</div>
						<div className="mb-4">
							<h2 className="mb-2 text-darkGreenColor flex items-center bg-lightGreenColor p-2">
								<p className='font-bold mr-2 font-sans text-[27px] leading-5 h-10 w-10 grid place-items-center rounded-full border-[1px] border-darkGreenColor'>3</p>
								<p className='text-[28px]'>Effortless and Quick Delivery</p>
							</h2>
							<p className='text-md text-base font-sans pl-4 pr-4'>With approval and documents signed, your funds will swiftly flow into your business account in as little as 3 days! Say goodbye to long waits and hello to your business's bright future!</p>
						</div>
					</div>
      
					<div className="w-full md:lg:w-1/2 lg:w-1/2 bg-darkCreamColor border-[1px] rounded-[2px] border-[#D1D1D1] p-4">
						<div className="max-w-md mx-auto">
							<h2 className="text-[25px] font-bold mb-6 text-center text-darkGreenColor">Take the first step. Apply now!</h2>
							<div>
								<div className="mb-4">
									<input
										className="w-full p-2 border border-black text-black placeholder:text-black rounded-[2px] outline-none font-sans"
										type="text"
										name="firstName"
										placeholder='First Name'
										value={formData.firstName}
										onChange={handleChange}
									/>
									{validationErrors.firstName && <p className="text-red-500 text-sm">{validationErrors.firstName}</p>}
								</div>
								<div className="mb-4">
									<input 
										className="w-full p-2 border border-black text-black placeholder:text-black rounded-[2px] outline-none font-sans"
										type="text"
										name="lastName"
										placeholder='Last Name'
										value={formData.lastName}
										onChange={handleChange}
									/>
									{validationErrors.lastName && <p className="text-red-500 text-sm">{validationErrors.lastName}</p>}
								</div>
								<div className="mb-4">
									<input
										className="w-full p-2 border border-black text-black placeholder:text-black rounded-[2px] outline-none font-sans"
										type="tel"
										maxLength={10}
										name="mobilePhone"
										placeholder='Mobile Phone'
										value={formData.mobilePhone}
										onChange={handleChange}  
									/>
									{validationErrors.mobilePhone && <p className="text-red-500 text-sm">{validationErrors.mobilePhone}</p>}
								</div>

								<div className="mb-6">
									<input
										className="w-full p-2 border border-black text-black placeholder:text-black rounded-[2px] outline-none font-sans"
										type="email"
										name="email"
										placeholder='Email'
										value={formData.email}
										onChange={handleChange}
									/>
									{validationErrors.email && <p className="text-red-500 text-sm">{validationErrors.email}</p>}
								</div>
								<div className="text-center">
									<button
										onClick={handleSubmitFormAndRedirect}
										className="bg-lightGreenColor text-darkGreenColor border border-darkGreenColor text-[20px] font-sans font-medium py-[4px] px-4 rounded disabled:cursor-progress active:opacity-80"
										disabled={loading}
									>
										{!loading ? "Save & Continue" : "Please wait..."}
									</button>
								</div>
							</div>
						</div>
						<p className="text-[15px] font-sans mt-4">
							By continuing, you consent to AKFSI's Terms of Service and Privacy Policy. Expect a friendly AKFSI representative to reach out by phone, email, or text to discuss your interest in our loan products. You have the power to unsubscribe from any communications at any time, and your information is secure with us. View our <a className='underline font-sans font-semibold' href="/">TERMS & CONDITIONS</a>. Your financial journey starts here!
						</p>
					</div>
    		</div>
			</div>

			{/* invisible form for hubspot */}
			<iframe title="submit_form" name="submit_form" id="submit_form" style={{display: 'none'}} />
			<form
				className="registration-form"
				target="submit_form"
				method="POST"
				action="https://forms.hubspot.com/uploads/form/v2/6601487/6354487f-94b0-47b0-bdc5-8a2844d7f901"
				hidden
				onSubmit={(e) => e.preventDefault()}
			>
				<input type="text" name="firstname" placeholder="First Name" value={formData.firstName} />
				<input type="text" name="lastname" placeholder="Last Name" value={formData.lastName} />
				<input type="text" name="mobilephone" placeholder="Mobile Phone" value={formData.mobilePhone} />
				<input type="text" name="email" placeholder="Email" value={formData.email} />
			</form>
		</div>
	)
}

export default FinanceForm