import React from 'react'

import ManufacturingImage from '../assets/manufacturing.png'
import RetailImage from '../assets/retail.png'
import HealthCarePracticesImage from '../assets/health-care-practices.png'
import TechnologyImage from '../assets/technology.png'
import ConstructionImage from '../assets/construction.png'
import ProfessionalServicesImage from '../assets/professional-services.png'

const IndustriesSection = () => {

	const IndustryOption = ({ title, description, image }) => (
		<div className='mx-[40px] my-[30px] w-[380px] text-justify text-lightBrownColor'>
			<img src={image} alt="manufacturing" />
			<p className='font-sans text-center mt-2 text-[25px] font-bold'>{title}</p>
			<p className='font-sans mt-2 text-[19px]'>{description}</p>
		</div>
	)

	return (
		<div className='bg-[#ebeaea] w-full py-4 px-12 flex flex-col items-center mb-[20px]'>
			<p className='text-mintGreenColor text-[28px]'>Expanding Our Reach Across Various Industries...</p>
			<p className='mt-8 text-[20px] font-sans text-lightBrownColor font-medium'>Our commitment extends to a wide spectrum of businesses! Over the past few years, we've fine-tuned our underwriting model to accommodate the diverse needs of numerous industries. We continue to provide the same unique loan products and unparalleled customer service. Here are just some examples:</p>

			<div className='mt-[50px] w-full flex flex-wrap justify-center'>
				<IndustryOption
					image={ManufacturingImage}
					title='Manufacturing'
					description='Whether you operate a large-scale manufacturing facility or a small workshop, AKFSI offers specialized funding solutions to support your production needs. Unlock the funds you require to take your manufacturing business to the next level.'
				/>
				<IndustryOption
					image={RetailImage}
					title='Retail'
					description='In the world of retail, maintaining a steady flow of working capital is crucial. AKFSI understands the unique challenges retailers face and provides hassle-free financing to ensure your shelves stay stocked with the products your customers desire.'
				/>
				<IndustryOption
					image={HealthCarePracticesImage}
					title='Health Care Practices'
					description='As a healthcare provider, you focus on patient care, while AKFSI focuses on supporting your practice. Our loan products offer a lifeline to help you grow and provide quality healthcare services to your patients.'
				/>
				<IndustryOption
					image={TechnologyImage}
					title='Technology'
					description='Fueling Innovation for Tech Startups. Tech startups need financial support to turn their innovative ideas into reality. AKFSI is here to provide the necessary funds to help you navigate the dynamic world of technology and innovation.'
				/>
				<IndustryOption
					image={ConstructionImage}
					title='Construction'
					description="The construction industry demands substantial investment in materials, equipment, and labor. AKFSI's financing solutions are tailored to construction companies of all sizes, helping you take on projects and grow your business."
				/>
				<IndustryOption
					image={ProfessionalServicesImage}
					title='Professional Services'
					description='Professional service firms rely on a consistent flow of funds to deliver exceptional services. AKFSI provides financial backing to ensure that your firm can continue to thrive and meet the needs of your clients.'
				/>
			</div>

			<div className='flex flex-col md:flex-row lg:flex-row w-full justify-between items-center'>
				<p className='text-[28px] md:text-[35px] lg:text-[35px] text-mintGreenColor'>We can finance almost any industry! </p>
				<p className='mt-4 md:m-0 lg:m-0 rounded-2xl border border-darkGreenColor text-[20px] md:text-[28px] lg:text-[28px] text-darkGreenColor font-sans font-medium bg-lightGreenColor py-2 px-6'>See what other businesses we can finance</p>
			</div>
		</div>
	)
}

export default IndustriesSection