import React from 'react'
import HeroSectionImage from '../assets/hero-section-image.png'

const HeroImageSection = () => {
	return (
		<div
			className='relative h-[496px] w-full'
			style={{
				background: `url(${HeroSectionImage}) no-repeat`,
				backgroundSize: 'fill'
			}}
		>
			<p className='absolute top-[100px] left-[100px] text-[50px] text-white text-center bg-black pl-10 pr-10 pt-5 pb-5 bg-opacity-20 rounded-3xl backdrop-blur-sm'>Where Trust <br />Meets <br />Financial<br />Innovation</p>
		</div>
	)
}

export default HeroImageSection