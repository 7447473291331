import React, { useState } from 'react'
import MenuIcon from '../icons/HamburgerMenu.svg'
import AKFSILogo from '../assets/akfsi-logo-black.png'
import Menu from './Menu'

const Header = () => {

	const [isMenuOpened, setIsMenuOpened] = useState(false)

	return (
		<div className='relative flex px-[20px] md:px-[50px] lg:px-[50px] h-[160px] w-full bg-creamColor '>
			<div className='h-full grid place-items-center cursor-pointer' onClick={() => setIsMenuOpened(!isMenuOpened)}>
				<img className='w-fit h-[20px] md:h-[30px] lg:h-[30px] mt-auto mb-auto select-none pointer-events-none' src={MenuIcon} alt="icon" />
			</div>
			<img className='top-0 bottom-0 left-0 right-0 absolute m-auto h-[40px] md:h-[50px] lg:h-[50px]' src={AKFSILogo} alt="logo" />
			
			{
				isMenuOpened
				&&
				<Menu setIsMenuOpened={setIsMenuOpened}/>
			}
		</div>
	)
}

export default Header